<template>
  <div>
    <h2>{{ $t('form.headline') }}</h2>
    <template v-if="formComplete">
      <div class="music-submit">
        <div><i class="fal fa-comment-music"></i></div>
        <p>{{ $t('form.complete') }}</p>
      </div>
    </template>
    <template v-else>
      <div class="form">
        <input v-model="title" :placeholder="$t('form.input.placeholder.music') + ' *'" :disabled="!formEnabled" maxlength="50"/>
        <input v-model="artist" :placeholder="$t('form.input.placeholder.artist') + ' *'" :disabled="!formEnabled" maxlength="50"/>
        <textarea v-model="text" :placeholder="$t('form.input.placeholder.text')" :disabled="!formEnabled" rows="2"  maxlength="100"></textarea>
        <Alert v-if="warningText.length > 0" :msg="warningText" />
        <button :disabled="!formEnabled" @click="onSendBtnClick">{{ btnSubmit }}</button>
        <div class="mandatory"><small>* {{ $t('form.input.mandatory') }}</small></div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';

import Alert from '@/components/elements/Alert.vue';

export default {
  components: {
    Alert,
  },
  data() {
    return {
      warningText: '',
      formEnabled: true,
      formComplete: false,
      title: '',
      artist: '',
      text: '',
      btnSubmit: this.$t('form.input.submit'),
    };
  },
  methods: {
    onSendBtnClick() {
      if (this.title.trim().length <= 0 || this.artist.trim().length <= 0) {
        this.warningText = this.$t('alert.required');
        return;
      }
      this.btnSubmit = '';
      this.warningText = '';
      this.formEnabled = false;

      this.pushToServer();
    },
    async pushToServer() {
      const postData = {};
      postData.title = this.title.trim();
      postData.artist = this.artist.trim();
      if (this.text.trim().length > 0) postData.text = this.text.trim();

      try {
        const resp = await axios.post(`${process.env.VUE_APP_GATEWAY_URL}/client/request`, postData);

        if (resp.status === 201) {
          this.formComplete = true;
          return;
        }

        console.log(`Server status: ${resp.status}`);
      } catch (err) {
        console.error(err);
      }

      this.formEnabled = true;
      this.btnSubmit = this.$t('form.input.submit');
      this.warningText = this.$t('alert.server.connection');
    },
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.music-submit{
  padding: 1rem 0rem;
}
.music-submit i{
  font-size: 5rem;
  animation-name: zoom;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
.music-submit p{
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  margin-top: 1rem;
  color: var(--goass-color-primary);
}

.logo-dj{
  height: 300px;
  width: auto;
  display: block;
  margin: auto;
}

.mandatory{
  text-align: left;
  margin-top: 10px;
}

.form input,
.form textarea,
.form button{
  display: block;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  border-radius: var(--goass-size-radius);
  outline: none;
  padding: .6rem .5rem;
  color: var(--goass-color-primary);
  color: black;
  border: 2px solid var(--goass-color-primary);
  font-size: 1;
  text-align: center;
}
.form textarea{
    resize: vertical;
}
.form button{
  cursor: pointer;
  background: var(--goass-color-primary);
  border: 2px solid var(--goass-color-primary);
  color: white;
  position: relative;
  text-transform: uppercase;
  padding: 0;
  line-height: 3rem;
  min-height: 3rem;
  height: 3rem;
}
.form button:hover{
  background: var(--goass-color-primary-dark);
}
.form .ohnohoney{
  display: none;
}
.form input:disabled,
.form textarea:disabled,
.form button:disabled {
    cursor: not-allowed;
}
.form input:disabled,
.form textarea:disabled {
    background: #cfcfcf;
    border-color: #a3a3a3;
    color: #6c6c6c;
}
.form button:disabled:before {
  position: absolute;
  content: ' ';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 1rem;
  width: 1rem;
  background: transparent;
  border: 3px solid white;
  border-top-color: transparent;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.form > *{
  margin-top: 10px !important;
}

  @keyframes rotate {
    from {  transform: translate(-50%, -50%) rotate(0deg);    }
    to   {  transform: translate(-50%, -50%) rotate(360deg);}
  }
  @keyframes zoom {
    0% {  transform: scale(0); }
    70%   {  transform: scale(1.1); }
    100%   {  transform: scale(1); }
  }
</style>
