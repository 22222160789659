<template>
  <div :class="$style.container">
    <Header />
    <main>
      <template v-if="formComplete">
        <div :class="$style.loader">
          <Alert v-if="alertText.length > 0" :msg="alertText" />
          <Loader v-else />
        </div>
      </template>
      <template v-else>
        <div :class="$style.content">
          <template v-if="!isReady">
            <div :class="$style.notAvailable">
              <div><i class="fal fa-clock"></i></div>
              <strong>Zwischen 16:00 und 19:00 Uhr</strong> den QR-Code scannen und den Musikwunsch eintragen!
            </div>
          </template>
          <template v-else>
            <Form />
            <Voting v-if="historyList.length > 0" :history="historyList" />
          </template>
        </div>
      </template>
    </main>
    <Footer />
  </div>
</template>

<style module>
  .container{
    width: 100%;
    margin: auto;
    margin-top: 0px;
  }
  .loader{
    padding: 3rem 0rem;
  }
  .content{
    padding: 0rem 1rem;
    box-sizing: border-box;
  }
  .notAvailable{
    font-size: 1.3rem;
    padding: 2rem .7rem;
    border: 2px solid var(--goass-color-primary);
    border-radius: var(--goass-size-radius);
    box-sizing: border-box;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .notAvailable i{
    font-size: 3rem;
    padding-bottom: 1.5rem;
  }
  .notAvailable strong{
    color: var(--goass-color-primary);
  }

  @media (min-width: 550px) {
    .container{
      width: var(--goass-size-breakpoint);
    }
  }
</style>

<script>
import axios from 'axios';

import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';
import Form from '@/components/sections/Form.vue';
import Voting from '@/components/sections/Voting.vue';
import Loader from '@/components/elements/Loader.vue';
import Alert from '@/components/elements/Alert.vue';

export default {
  data() {
    return {
      historyList: [],
      formComplete: true,
      isReady: false,
      alertText: '',
    };
  },
  components: {
    Header,
    Voting,
    Form,
    Footer,
    Loader,
    Alert,
  },
  methods: {
    async initServer() {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_GATEWAY_URL}/client/init`);
        console.log(resp.data);
        if (resp.status === 200) {
          if (resp.data.history) {
            this.historyList = resp.data.history;
          }

          if (resp.data.isEnabled && resp.data.isEnabled === true) {
            this.isReady = true;
            this.formComplete = false;
          }
          return;
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      this.alertText = this.$t('alert.server.connection');
    },
  },
  mounted() {
    // setTimeout(() => { this.isReady = true; this.formComplete = false; }, 500);
    // setTimeout(() => { this.initServer(); }, 1000);
    this.initServer();
  },
};
</script>
