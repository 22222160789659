<template>
  <footer>
      <div>
        <a :href="urlLegalNotice" target="_blank">{{ $t('footer.legalNotice') }}</a>
      </div>
      <div>
        <a :href="urlGarger" target="_blank">{{ $t('footer.copyright') }}</a>
      </div>
  </footer>
</template>

<style module>
footer{
  padding-top: 3rem;
  padding-bottom: 1rem;
}

footer a{
  cursor: pointer;
  color: var(--goass-color-text);
  font-size: .8rem;
  text-decoration: none;
  opacity: .5;
}

footer a:hover{
  color: var(--goass-color-text);
  text-decoration: none;
  opacity: 1;
}
</style>

<script>
export default {
  data() {
    return {
      urlGarger: process.env.VUE_APP_URL_GARGER,
      urlLegalNotice: process.env.VUE_APP_URL_LEGAL_NOTICE,
    };
  },
};
</script>
