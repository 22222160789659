<template>
  <div>
    <h2>Vote Deinen<br />Lieblingshit
      <i18n path="voting.headline" tag="h2">
        <br />
      </i18n>
    </h2>
      <div>
        <template v-if="historyList.length > 0">
          <div :class="{ [$style.voteEntry]: true, [$style.voted]: item.voted}" v-for="(item, index) in historyList" :key="item.id" @click="onSendBtnClick(index)">
            <div :class="$style.title">{{item.title}}</div>
            <div class="artist">{{item.artist}}</div>
          </div>
        </template>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    history: Array,
  },
  data() {
    return {
      historyList: [],
    };
  },
  components: {
  },
  mounted() {
    this.historyList = [];
    /* eslint operator-assignment: ["error", "never"] */
    for (let i = 0; i < this.history.length; i = i + 1) {
      const item = this.history[i];
      this.historyList.push({
        id: item.id,
        title: item.title,
        artist: item.artist,
        voted: false,
      });
    }
  },
  methods: {
    onSendBtnClick(index) {
      if (this.historyList[index].voted) return;
      this.historyList[index].voted = true;
      this.pushToServer(this.historyList[index].id);
    },
    async pushToServer(id) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_GATEWAY_URL}/client/vote/${id}`);
        console.log(resp.status);
        if (resp.status === 201) {
          this.formComplete = true;
          return;
        }

        console.log(resp.status);
        console.log(resp.data);
      } catch (err) {
        console.error(err);
      }

      this.warningText = 'Fehler bei der Server-Verbindung!';
      this.formEnabled = true;
      this.btnSubmit = 'Absenden';
    },
  },
};
</script>

<style module>
  .voteEntry{
    margin-top: 10px;
    border: 2px solid var(--goass-color-primary);
    text-align: left;
    border-radius: var(--goass-size-radius);
    cursor: pointer;
    padding: 7px 10px;
    position: relative;
    background: #86807e;
  }
  .voteEntry:after{
    position: absolute;
    content: "\f005";

    top: 50%;
    right: .4rem;
    transform: translate(0%, -50%);
    font-size: 1.5rem;

    font-family: "Font Awesome 5 Pro";
    font-weight: 900;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .voted{
    border-color: var(--goass-color-yellow);
    cursor: default;
  }
  .voted:after{
    color: var(--goass-color-yellow);

    animation-name: select;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-delay: 0s;
  }

  @keyframes select {
    0% {  transform: translate(0%, -50%) scale(1);  }
    60%   {  transform: translate(0%, -50%) scale(1.4);  }
    100%   {  transform: translate(0%, -50%) scale(1);  }
  }

  .voteEntry:hover{
    background: #ffffff7a;
  }
  .title{
    font-weight: bold;
  }
</style>
