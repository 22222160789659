<template>
  <header>
    <div :class="$style.container">
      <div :class="$style.wrap">
        <img :class="$style.logo" src="@/assets/goass-logo.svg" />
        <h1 :class="$style.title">
          {{ $t('name') }}
        </h1>
      </div>
   </div>
  </header>
</template>

<style module>
  .container{
    background: url('~@/assets/jukebox.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin: auto;
    position: relative;
    overflow: hidden;
  }
  .container:before,
  .container:after{
     background: transparent;
     position: absolute;
     top: 0;
     bottom: 0;
     width: 50px;
     content: ' ';
     display: none;
  }
  .container:before{
    background: linear-gradient(90deg, var(--goass-color-bg), transparent);
    left: 0;
  }
  .container:after{
    background: linear-gradient(90deg, transparent, var(--goass-color-bg));
    right: 0;
  }

  .wrap{
      width: 55%;
      margin-left: 35%;
     display: flex;
     flex-direction: column;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
     align-content: center;
     padding: 1rem;
    box-sizing: border-box;
  }

  .logo{
    height: auto;
    width: 100%;
  }

  h1.title{
    color: var(--goass-color-primary);
    font-size: 4rem;
    line-height: 1;
    margin: 0;
    padding: 0;
    padding-top: .5rem;
  }

  @media (min-width: 550px) {
    .container:before,
    .container:after{
      display: block;
    }
  }
</style>
