<template>
  <div :class="$style.container">
    <div :class="$style.circle"></div>
  </div>
</template>

<style module>
  .container{
    padding: 2rem;
  }

  .circle{
    text-align: center;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background: transparent;
    border: 3px solid var(--goass-color-primary);
    border-top-color: transparent;
    border-radius: 50%;
    animation-name: infiniteRotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes infiniteRotate {
    from {  transform: rotate(0deg);    }
    to   {  transform: rotate(360deg);}
  }
</style>
