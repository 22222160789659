<template>
  <div :class="$style.warning">
    {{ msg }}
  </div>
</template>

<style module>
.warning{
    white-space: pre-wrap;
    color: white;
    font-weight: bold;
    background: rgb(255 68 68);
    padding: 5px;
    border-radius: var(--goass-size-radius);
    line-height: 1.3;
    font-size: 0.9rem;
    margin: 0;

    background: transparent;
    color: rgb(255 68 68);
}
</style>

<script>
export default {
  props: {
    msg: String,
  },
};
</script>
